@font-face {
  font-family: 'Oxygen';
  font-weight: 400;
  font-style: normal;
  src: url('https://fonts.gstatic.com/s/oxygen/v5/RuRdwqUdkfYP0fpTfyGHiA.eot');
  src: url('https://fonts.gstatic.com/s/oxygen/v5/RuRdwqUdkfYP0fpTfyGHiA.eot?#iefix') format('embedded-opentype'),
       local('Oxygen'),
       local('Oxygen-regular'),
       url('https://fonts.gstatic.com/s/oxygen/v5/qBSyz106i5ud7wkBU-FrPevvDin1pK8aKteLpeZ5c0A.woff2') format('woff2'),
       url('https://fonts.gstatic.com/s/oxygen/v5/eAWT4YudG0otf3rlsJD6zOvvDin1pK8aKteLpeZ5c0A.woff') format('woff'),
       url('https://fonts.gstatic.com/s/oxygen/v5/f8OBjBbevvywgbyJOxlO7Q.ttf') format('truetype'),
       url('https://fonts.gstatic.com/l/font?kit=yh90VGZKR0bnBJLT_XYX1g#Oxygen') format('svg');
}
@font-face {
  font-family: 'Oxygen';
  font-weight: 700;
  font-style: normal;
  src: url('https://fonts.gstatic.com/s/oxygen/v5/yVHpdQrmTj9Kax1tmFSx2vY6323mHUZFJMgTvxaG2iE.eot');
  src: url('https://fonts.gstatic.com/s/oxygen/v5/yVHpdQrmTj9Kax1tmFSx2vY6323mHUZFJMgTvxaG2iE.eot?#iefix') format('embedded-opentype'),
       local('Oxygen Bold'),
       local('Oxygen-700'),
       url('https://fonts.gstatic.com/s/oxygen/v5/yVHpdQrmTj9Kax1tmFSx2pBw1xU1rKptJj_0jans920.woff2') format('woff2'),
       url('https://fonts.gstatic.com/s/oxygen/v5/yVHpdQrmTj9Kax1tmFSx2rO3LdcAZYWl9Si6vvxL-qU.woff') format('woff'),
       url('https://fonts.gstatic.com/s/oxygen/v5/yVHpdQrmTj9Kax1tmFSx2qCWcynf_cDxXwCLxiixG1c.ttf') format('truetype'),
       url('https://fonts.gstatic.com/l/font?kit=yVHpdQrmTj9Kax1tmFSx2pbd9NUM7myrQQz30yPaGQ4#Oxygen') format('svg');
}
@font-face {
  font-family: 'Oxygen';
  font-weight: 300;
  font-style: normal;
  src: url('https://fonts.gstatic.com/s/oxygen/v5/AwBqWF2kjhlybWamaKMPcfY6323mHUZFJMgTvxaG2iE.eot');
  src: url('https://fonts.gstatic.com/s/oxygen/v5/AwBqWF2kjhlybWamaKMPcfY6323mHUZFJMgTvxaG2iE.eot?#iefix') format('embedded-opentype'),
       local('Oxygen Light'),
       local('Oxygen-300'),
       url('https://fonts.gstatic.com/s/oxygen/v5/AwBqWF2kjhlybWamaKMPcZBw1xU1rKptJj_0jans920.woff2') format('woff2'),
       url('https://fonts.gstatic.com/s/oxygen/v5/AwBqWF2kjhlybWamaKMPcbO3LdcAZYWl9Si6vvxL-qU.woff') format('woff'),
       url('https://fonts.gstatic.com/s/oxygen/v5/AwBqWF2kjhlybWamaKMPcaCWcynf_cDxXwCLxiixG1c.ttf') format('truetype'),
       url('https://fonts.gstatic.com/l/font?kit=AwBqWF2kjhlybWamaKMPcZbd9NUM7myrQQz30yPaGQ4#Oxygen') format('svg');
}
@import 'external_css/font-awesome','bootstrap','jquery_ui','jplayer_skin','sweet_alerts','formvalidation';
//BODY
html{
  height: 100%;
}
body{
  height: 100%;
  background: url("/images/main_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
//LOGIN
#login_logo{
  margin: 20px 0 0;
}
//MAIN NAVIGATION
#main_navigation{
  padding: 20px 0;
  #showcase_logo{
    max-height: 75px;
  }
  .menu_btn{
    float: right;
    text-align: center;
    margin: -20px 10px 0 10px;
    padding: 20px 0 0 0;
    position: relative;
    font-size: 70%;
    .menu_icon{
      width: 45px;
      height: 45px;
      background: url("/images/icons_sprite.png");
      background-size: 360px 90px;
      display: block;
    }
    #menu_playlist{
      background-position: -315px top;
      &:hover,&:active,&.active{
        background-position: -315px bottom;
      }
    }
    #menu_help{
      background-position: -225px top;
      &:hover,&:active,&.active{
        background-position: -225px bottom;
      }
    }
    #menu_settings{
      background-position: -270px top;
      &:hover,&:active,&.active{
        background-position: -270px bottom;
      }
    }
    #menu_logout{
      background-position: -180px top;
      &:hover,&:active,&.active{
        background-position: -180px bottom;
      }
    }
  }
  .menu_btn.active:before{
    content: "";
    position: absolute;
    top: 0;
    border-top: solid 10px #777;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    left: 12px;
  }
  .menu_btn.active{
    #menu_playlist{
      background-position: -315px bottom;
    }
    #menu_help{
      background-position: -225px bottom;
    }
    #menu_settings{
      background-position: -270px bottom;
    }
    #menu_logout{
      background-position: -180px bottom;
    }
  }
}
//VIDEO LISTS
.list_title{
  float: left;
  width: 70%;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 2px 0 0 0;
}
.play_video,.delete_video,.delete_playlist,.link_playlist{
  float: right;
  margin: 0 5px;
  display: none;
  height: 25px;
  width: 25px;
  background: url("/images/icons_sprite.png");
  &:hover,&:active,&.active{
    cursor: pointer;
  }
}
.play_video{
  background-position: -50px top;
  background-size: 200px 50px;
  &:hover,&:active,&.active{
    background-position: -50px bottom;
  }
}
.delete_video,.delete_playlist{
  background-position: -75px top;
  background-size: 200px 50px;
  &:hover,&:active,&.active{
    background-position: -75px bottom;
  }
}
.link_playlist{
  background-position: 0 top;
  background-size: 200px 50px;
  &:hover,&:active,&.active{
    background-position: 0 bottom;
  }
}
.movie_clip_container{
  &:hover,&:active,&.active{
    cursor: default;
    background: rgb(171, 237, 255) !important;
    .play_video{
      display: block !important;
    }
  }
}
.video_container{
  &:hover,&:active,&.active{
    cursor: default;
    background: rgb(185, 255, 171) !important;
    .play_video,.delete_video{
      display: block !important;
    }
  }
}
.playlist_container{
  &:hover,&:active,&.active{
    cursor: default;
    background: rgb(255, 214, 171) !important;
    .delete_playlist,.link_playlist{
      display: block;
    }
  }
}
.movie_clip_container,.video_container,.playlist_container{
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
  height: 45px;
}
//PLAYLIST PAGE
#movie_clips_container,#current_playlist_container{
  height: calc(100% - 170px);
}
#movie_clips_list_container,#current_playlist_list_container{
  height: 90%;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  background: rgba(255,255,255,0.6);
  padding-top: 10px;
  .category_container{
    padding: 10px 15px;
    background: #555;
    color: #fff;
  }
  #movie_clips_list{
    list-style: none;
    .movie_clip_category_container{
      padding: 10px 5px;
      background-color: #555;
      color: #fff;
    }
    .movie_clip_container{
      padding: 10px 15px;
    }
    .movie_clip_container:nth-child(odd){
      background: #ccc;
    }
  }
  #current_playlist_list{
    list-style: none;
    .list_holder{
      @extend .row;
      height: 45px;
      background: rgb(185, 255, 171);
    }
    .video_container{
      padding: 10px 15px;
    }
    .video_container:nth-child(odd){
      background: #ccc;
    }
  }
  #movie_clips_list,#current_playlist_list{
    margin: 0;
    padding: 0;
  }
  #movie_clips_wrapper,#current_playlist_wrapper{
    overflow: hidden;
    height: calc(100% - 75px);
    margin: 0;
  }
  #playlist_save,#playlist_destroy{
    background: #fff;
    &:hover,&:active,&.active{
      cursor: pointer;
    }
    .icon{
      background-image: url("/images/icons_sprite.png");
      background-size: 176px 44px;
      width: 22px;
      height: 22px;
    }
  }
  #playlist_save{
    border-width: 1px 0 1px 0;
    .icon{
      background-position: -22px top;
      &:hover,&:active,&.active{
        background-position: -22px bottom;
      }
    }
  }
  #playlist_destroy{
    border-width: 1px 1px 1px 0;
    .icon{
      background-position: -66px top;
      &:hover,&:active,&.active{
        background-position: -66px bottom;
      }
    }
  }
}
#saved_playlists_container{
  height: calc(60% - 170px);
  margin-bottom: 15px;
}
#saved_playlists_wrapper{
  height: calc(100% - 45px);
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  background: rgba(255,255,255,0.6);
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  #saved_playlists_list_container{
    list-style: none;
    padding: 0;
    .category_container{
      padding: 10px 15px;
      background: #555;
      color: #fff;
    }
    .playlist_container{
      padding: 10px 15px;
    }
    .playlist_container:nth-child(odd){
      background: #ccc;
    }
  }
}
#movie_clips_container{
  .h4{
    color: rgb(0, 28, 213);
    font-weight: bold;
  }
}
#movie_clips_list_container{
  border-color: rgb(0, 28, 213);
}
#current_playlist_container{
  .h4{
    color: rgb(2, 99, 0);
    font-weight: bold;
  }
}
#current_playlist_list_container{
  border-color: rgb(2, 99, 0);
}
#saved_playlists_container{
  .h4{
    color: rgb(193, 104, 0);
    font-weight: bold;
  }
}
#saved_playlists_wrapper{
  border-color: rgb(193, 104, 0);
}
#movie_clips_divider{
  margin: 15px 0;
  border-bottom: 1px solid rgb(0, 28, 213);
}
#current_playlist_divider{
  margin: 0 0 15px;
  border-bottom: 1px solid rgb(2, 99, 0);
}
#control_playlist_container{
  text-align: center;
  font-size: 130%;
  #play_current_playlist{
    width: 128px;
    height: 128px;
    background: url("/images/icons_sprite.png");
    background-position: -256px top;
    margin: auto;
    &:hover,&:active,&.active{
      cursor: pointer;
      background-position: -256px bottom;
    }
  }
}
//VIDEO MODAL
#jp_container_1{
  margin: auto;
  .jp-playlist{
    display: none !important;
  }
}
//LINK MODAL
#link_modal_play{
  text-align: center;
  #link_modal_play_icon{
    width: 45px;
    height: 45px;
    display: inline-block;
    vertical-align: middle;
    background-image: url("/images/icons_sprite.png");
    background-size: 360px 90px;
    background-position: -90px top;
  }
  &:hover,&:active #link_modal_play_icon{
    background-position: -90px bottom;
  }
}
//SETTINGS PAGE
#account_settings_container,#uploaded_video_settings_container,#custom_video_settings_container,#user_uploaded_videos{
  display: none;
  height: calc(85% - 170px);
}
#account_settings_wrapper,#uploaded_video_wrapper,#custom_video_wrapper{
  height: calc(100% - 44px);
  border: 1px solid #000;
  border-radius: 10px;
  background: rgba(255,255,255,0.6);
  overflow-y: hidden;
  .category_container{
    padding: 10px 15px;
    background: #555;
    color: #fff;
  }
  .video_container{
    padding: 10px 15px;
  }
  .video_container:nth-child(odd){
    background: #ccc;
  }
}
#account_settings,#uploaded_video_settings,#custom_video_settings{
  padding: 10px 0;
  overflow: hidden;
}
#uploaded_video_settings_upload{
  margin: 5px 0;
  text-align: center;
  #select_video_category{
    display: none;
    position: fixed;
    width: 80%;
    left: 10%;
    top: 40%;
    padding: 2%;
    background-color: #fff;
    border-radius: 10px;
    border: 5px solid #000;
    #default_category_container{
      margin: 10px 0 20px;
    }
  }
  #upload_custom_video{
    display: none;
  }
}
.uploadProgress{
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: #FFF;
  .progress_container{
    margin-top: 10%;
  }
}
#view_full_screen{
  width: 480px;
  margin: 10px auto;
  display: block;
  font-size: 140%;
  background-color: #eee;
  border: 1px solid #009be3;
  padding: 10px 0;
}
#view_full_screen:hover,#view_full_screen:active{
  background-color: #fff;
  cursor: pointer;
}
.infostar_logo{
  position: fixed;
  right: 10px;
  bottom: 10px;
}
